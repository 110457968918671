import { login, logout, getInfo } from '@/api/auth';
import { isLogged, setLogged, removeToken } from '@/utils/auth';
import router from '@/router';
import store from '@/store';

const state = {
  id: null,
  user: null,
  token: isLogged(),
  name: '',
  phone: '',
  avatar: '',
  introduction: '',
  roles: [],
  permissions: [],
  country: '',
  state: '',
  district: '',
  city: '',
  zip_code: '',
  street: '',
  image: '',
  authUserRole: '',
};

const mutations = {
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
  SET_COUNTRY: (state, country) => {
    state.country = country;
  },
  SET_STATE: (state, states) => {
    state.states = states;
  },
  SET_DISTRICT: (state, district) => {
    state.district = district;
  },
  SET_CITY: (state, city) => {
    state.city = city;
  },
  SET_ZIP_CODE: (state, zip_code) => {
    state.zip_code = zip_code;
  },
  SET_STREET: (state, street) => {
    state.street = street;
  },
  SET_IMAGE: (state, image) => {
    state.image = image;
  },
  SET_AUTH_USER_ROLE: (state, authUserRole) => {
    state.authUserRole = authUserRole;
  },
};

const actions = {

  login({ commit }, userInfo) {

    const { email, password } = userInfo;

    return new Promise((resolve, reject) => {

      login({ email: email.trim(), password: password })
        .then(response => {
          setLogged('1');
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });

  },

  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then(response => {
          const { data } = response;

          if (!data) {
            reject('Verification failed, please Login again.');
          }

          const { roles, authUserRole, phone, name, country, state, district, zip_code, street, image, avatar, introduction, permissions, id } = data;
          // roles must be a non-empty array
          if (!roles || roles.length <= 0) {
            reject('getInfo: roles must be a non-null array!');
          }

          commit('SET_ROLES', roles);
          commit('SET_PERMISSIONS', permissions);
          commit('SET_NAME', name);
          commit('SET_PHONE', phone);
          commit('SET_COUNTRY', country);
          commit('SET_STATE', state);
          commit('SET_DISTRICT', district);
          commit('SET_ZIP_CODE', zip_code);
          commit('SET_STREET', street);
          commit('SET_IMAGE', image);
          commit('SET_AVATAR', avatar);
          commit('SET_INTRODUCTION', introduction);
          commit('SET_ID', id);
          commit('SET_AUTH_USER_ROLE', authUserRole);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  logout({ commit }) {

    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          removeToken();
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  },

  changeRoles({ commit, dispatch }, role) {
    return new Promise(async resolve => {
      // const token = role + '-token';

      // commit('SET_TOKEN', token);
      // setLogged(token);

      // const { roles } = await dispatch('getInfo');

      const roles = [role.name];
      const permissions = role.permissions.map(permission => permission.name);
      commit('SET_ROLES', roles);
      commit('SET_PERMISSIONS', permissions);
      router.push('/');

      const accessRoutes = await store.dispatch('permission/generateRoutes', { roles, permissions });

      router.addRoute(accessRoutes);

      resolve();
    });
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
